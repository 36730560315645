

    .hero {
        position: relative;
        height: 150px;

        .background {
            height: 100%;
            width: 100%;
            left: 50%;
            top: 50%;

            z-index: 0;
            overflow: hidden;


            .background-inner {

                height: calc(100% + 40px);
                width: calc(100% + 40px);
                left: calc(50% - 20px);
                top: calc(50% - 20px);

                background-size: cover;
                filter: blur(2px);
                -webkit-filter: blur(2px);
            }
        }

        .background-overlay {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(0,0,0,0.35);
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;
            height: 100%;
        }
    }

    .product-card {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        flex-grow: 5;

        .uk-card-body {
            display: flex;
            flex-direction: column;
            flex-grow: 5;

        }

        .product-info-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 5;

            .product-cta{
                margin-top: auto;
            }
        }

        .uk-card-media-top {
            width: 100%;
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 100%;
                max-width: 100%;
                width: 100%;
                height: auto;
                margin: auto;

            }
        }

        .old-price {
            position: relative;
            top: -4px;
            text-decoration: line-through;

        }
    }

